@charset "UTF-8";

@import "modules/colors";
@import "modules/variables";

/*!
  Din Style
  Framework URI: https://github.com/nncl/Din-Style
  Author: Din Digital
  Author URI: http://dindigital.com/
  Description: Framework CSS usado pela Din Digital.
  Version: 1.0
*/

/**
 * Tabela de conteúdos
 *
 * 1.0 - Posicionamento
 *   1.1 - Centralizando conteúdos
 *   1.2 - Centralizando blocos
 *   1.3 - Centralizando verticalmente
 *
 * 2.0 - Formulários
 * 3.0 - Listas
 * 4.0 - Espaçamentos
 * 5.0 - Reset Inputs
 * x.0 - Geral
 */

 // ******* LET'S GO THEN *******

/*** 1.0 POSICIONAMENTO
****   1.1 - Centralizando conteúdos
***/

.dd-tcenter {
  text-align: center;
}

.dd-tleft {
  text-align: left;
}

.dd-tright {
  text-align: right;
}

.dd-tjustify {
  text-align: justify;
}

.dd-imgcenter {
  display: block;
  margin: 0 auto;
}

/*** - ***/

/***
**** 1.2 Centralizando blocos
***/

.dd-fcenter {
  display: block;
  margin: 0 auto;
}

.dd-fleft {
  float: left;
}

.dd-fright {
  float: right;
}

.dd-fnone {
  float: none;
}

/*** - ***/

/***
**** 1.3 Centralizando verticalmente
***/

.dd-box-center {
  display: -webkit-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-flex-direction: column;
}

/*** - ***/

/***
**** 2.0 Formulários
***/

.dd-load {
  display: inline-block;
  // width: 30px;
  // height: 30px;
  // background-image: $load;
  // background-repeat: no-repeat;
  // background-size: 100%;
  font: normal normal normal 14px/1 FontAwesome;

  &:before {
    content: "\f110";
  }
}

.dd-error {
  color: $red;
}

input,
textarea,
button,
select {
  &.dd-error {
    border: 1px solid $red;
  }
}

.dd-success {
  color: $green;
}

/*** - ***/

/***
**** 3.0 Listas
***/

.dd-inline-list {
  font-size: 0;

  & > * {
    display: inline-block;
  }
}

.dd-vtop {
  vertical-align: top;
}

.dd-vmiddle {
  vertical-align: middle;
}

.dd-vbottom {
  vertical-align: bottom;
}

.dd-block-list > * {
  display: block;
}

/*** - ***/

/***
**** 4.0 Espaçamentos
***/

.dd-margin-vertical {
  margin-top: 10px;
  margin-bottom: 10px;
}

.dd-margin-bottom {
  margin-bottom: 10px;
}

.dd-margin-top {
  margin-top: 10px;
}

.dd-padding-vertical {
  padding-top: 10px;
  padding-bottom: 10px;
}

.dd-padding-top {
  padding-top: 10px;
}

.dd-padding-bottom {
  padding-bottom: 10px;
}

/*** - ***/

/***
**** 5.0 Reset Inputs
***/

/* Remove button padding in FF */
button::-moz-focus-inner {
  border:0;
  padding:0;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
  margin-top:-2px;
  margin-bottom: -2px;
}

/*** - ***/

/***
**** x.0 Geral
****   x.1 Fontes
****   x.2 Mostrando/Escondendo elementos
***/

/***
**** x.1 Fontes
***/

.dd-upper {
  text-transform: uppercase;
}

.dd-lower {
  text-transform: lowercase;
}

/*** - ***/

/***
**** x.2 Escondendo/Mostrando elementos
***/

.dd-hide {
  display: none;
}

.dd-hidev {
  visibility: hidden;
}

.dd-show {
  display: inline-block;
}

.dd-showv {
  visibility: visible;
}

/*** - ***/
