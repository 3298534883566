// Colors
// --------------------------
$black: #000;
$white: #fff;

// Forms
$green: #3e9541;
$red:   #c91919;

$main: $black;
$mainhover: $white;
